<template>
  <div class="Guides_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item v-for="item in categoryList" :index="item.id" :key="item.id" :value="item.id">{{item.name}}</el-menu-item>
        </el-menu>
        <div class="GlisCentent" @click="guideClick(1)">指南管理</div>
      </div>
      <div class="headDetails">说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
    </div>

    <Details v-if="isShow" :DetailsId ="DetailsId" @childFn="parentFn"/>
    <div v-if="!isShow">
      <div class="BusinessNav">
        <div class="TableIpts">
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="keyword" placeholder="请输入名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          <div class="NavLiReset" @click="showAdd()"><i class="el-icon-plus"></i>创建合集</div>
        </div>
        <div class="MMMM">
          <span>推荐：</span>
          <span>南京助工</span>
          <span>苏州中级</span>
          <span>论文查重</span>
        </div>
      </div>

      <div class="GuidesNav">
        <div class="GuidesNavLi" v-for="item in tableData" :key="item.id" ref="GuidesNavRefs">
          <div class="GuidesNavLeft" @click="tiaozhuan(item)">
            <el-image @click="tiaozhuan(item)" :src="item.full_image" :fit="fit"></el-image>
          </div>
          <div class="GuidesNavRight" :class="isconWidth == true? 'hhh':''"  ref="GuidesNavRefs">
            <div class="GuidesNavName" @click="tiaozhuan(item)">{{item.name}}</div>
            <div class="GuidesNavAuthor">作者：{{item.author}} <span>|</span> 更新时间：{{item.createtime}}</div>
            <div class="GuidesNavBrief">{{item.intro}}</div>
            <div class="GuidesNavMaterial">
              <span v-for="(obj ,s) in item.tag" :key="s">{{obj}}</span>
            </div>
            <el-dropdown trigger="click">
              <i class="el-icon-more"></i>
              <template #dropdown>
                <el-dropdown-menu class="menu">
                  <el-dropdown-item class="menu__item" @click="HandleEdit(item)">修改</el-dropdown-item>
                  <el-dropdown-item class="menu__item" @click="HandleDel(item.id)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>


    <el-dialog :title="titleMath" v-model="formDialogVisible" width="33%">
      <el-form class="EditNewForm" style="padding:0 24px" label-width="100px">
        <el-form-item class="EditNewFormBtns" label="合集名称：">
          <el-input v-model="Row.name" placeholder="请输入合集名称：" class="El_ii"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="创作者：">
          <el-input v-model="Row.author" placeholder="请输入作者名称" class="El_ii"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div style="float:right;font-size: 14px;color: #0081FF;margin-right: 6px;" @click="guideClick(2)">内容管理</div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="合集标签：">
          <el-select v-model="Row.tag_ids" multiple placeholder="选择合集标签" style="width:100%">
            <el-option v-for="item in content" :key="item.value" :label="item.content" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="排序权重：">
          <el-input type="number" v-model="Row.weigh" placeholder="请输入文章简介" class="El_ii"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="合集简介：">
          <el-input type="textarea" v-model="Row.intro" placeholder="请输入文章简介" class="El_ii"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="封面图：">
          <el-upload
              ref="upload-image"
              list-type="picture-card"
              :action="upload"
              :headers="heads"
              :limit="1"
              :file-list="fileList"
              :on-success="handleSuccess"
              :on-exceed="handleExceed"
              :on-preview="handlePreview">
            <i class="el-icon-plus"></i>
            <div class="el_asff">上传头像</div>
            <div class="El_trrt">照片规格： 166像素*220像素</div>
          </el-upload>
        </el-form-item>

        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="addPanel == true " class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>


    <!--创建指南内容合集-->
    <el-dialog :title="titleMath" v-model="dialogVisible" width="35.7%">
      <div v-if="is_show == 0">
          <div class="Tabbl">
            <el-table
              :data="Category"
              class="table"
              header-cell-class-name="table-header">
              <el-table-column label="指南类别" width="240" align="center">
                <template #default="scope" >
                    <div class="El_pop dasdasd">
                      <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.name" placeholder="类别"></el-input>
                    </div>
                </template>
              </el-table-column>
              <el-table-column label="设置" width="180" align="center">
                  <template #default="scope">
                    <span >{{ {default:'预设',custom:'自定义'} [scope.row.type|| 'default' ] }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="是否启用" width="100">
                <template #default="scope" >
                  <el-switch v-model="scope.row.status"
                    :disabled = "scope.row.type == 'default' ? true : false"
                    :active-value = 1
                    :inactive-value = 0
                    active-color="#1890FF"
                    inactive-color="#dcdfe6"/>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" align="center" >
                <template #default="scope" >
                  <div class="el_butto rere dsdtrt" :disabled = "scope.row.type == 'default' ? true : false">
                      <i class="el-icon-delete" @click="guideDelete(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="YADD" @click="categoryAdd(1)"><i class="el-icon-plus"></i>创建类别</div>
          </div>
      </div>
      <div v-if="is_show == 1">
          <div class="Tabbl">
            <el-table
              :data="content"
              class="table"
              header-cell-class-name="table-header">
              <el-table-column label="导航类别" width="240" align="center">
                <template #default="scope" >
                    <div class="El_pop dasdasd">
                      <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
                    </div>
                </template>
              </el-table-column>
              <el-table-column label="设置" width="180" align="center">
                  <template #default="scope">
                    <span >{{ {default:'预设',custom:'自定义'} [scope.row.type|| 'default' ] }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="是否启用" width="100">
                <template #default="scope" >
                  <el-switch v-model="scope.row.status"
                    :disabled = "scope.row.type == 'default' ? true : false"
                    active-value = 'normal'
                    inactive-value = 'hidden'
                    active-color="#1890FF"
                    inactive-color="#dcdfe6"/>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" align="center" >
                <template #default="scope" >
                  <div class="el_butto rere dsdtrt" :disabled = "scope.row.type == 'default' ? true : false">
                      <i class="el-icon-delete" @click="guideDelete2(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="YADD" @click="categoryAdd(2)"><i class="el-icon-plus"></i>创建类别</div>
          </div>
      </div>
      <div style="text-align: right;">
        <div class="dialog-footer">
          <el-button @click="Close(1)" style="background: #fff;color: #595959; border: 1px solid #ccc;">取 消</el-button>
          <el-button v-if="is_show == 0" type="primary" @click="guideAdd(1)">确 定</el-button>
          <el-button v-if="is_show == 1" type="primary" @click="guideAdd(2)">确 定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import axios from 'axios'
import Details from './Details.vue'
export default {
  components:{
    Details
  },
  name: "basetable",
  data() {
    return {
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      tableData:[],   //总列表
      content:[],
      fileList:[],  //图片
      Category:[],  //类别
      activeIndex:'',
      categoryList:[],
      total:'',
      page: {   // 分页
        currentPage: 1,
        pageSize: 9,
      },
      keyword:'',
      addPanel: false,  //编辑新增显示隐藏
      editPanel: false,
      formDialogVisible:false,
      dialogVisible:false,
      isguideId:false,
      titleMath:'',
      Bkdi_id:'',  //编辑id
      is_show:'',
      isShow: false,
      dataId:'',
      isconWidth:false,
      Row:{
          name:'',
          author:'',
          tag_ids:[],
          intro:'',
          image:'',
          weigh:'',
          category_id: ''
      },
    };
  },
  mounted: function() {
    var that = this
    setInterval(()=>{
      const conWidth = document.querySelector('.GuidesNavLeft').clientWidth
      const GuidesNavRight = document.querySelector('.GuidesNavRight')
      if(conWidth < 81){
        GuidesNavRight.style.width = '234px'
        that.isconWidth = true
      }
    },1000)
    that.Ms_username = window.localStorage.getItem("ms_username")
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.mokuai()

  },

  methods: {
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'GET',
        url: '/admin/workbench/guide/bookList',
        params: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          keyword: that.keyword,
          category_id: that.activeIndex
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //导航选择
    handleSelect_top(e){
      var that = this
      that.activeIndex = e
      that.isShow = false
      that.loadTable()
    },
    // 创建打开
    showAdd(){
      var that= this
      that.addPanel = true;
      that.editPanel = false;
      that.formDialogVisible = true
      that.titleMath = '创建指南合集'
      that.contentList()
      that.Row = {
        name:'',
        author:'',
        tag_ids:[],
        intro:'',
        image:'',
        weigh:0,
        category_id:''
      }
      that.fileList = [];
    },
    // 合集标签
    contentList(){
        var that= this
        axios.get('/admin/company/content/index?field_key=guide_tag',{   // 模块
          }).then(function (res) {
          console.log(res.data.dada);
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          } else{
            that.content = res.data.data.enums
          }
        })
    },
    //模块
    mokuai(){
      var that = this
      axios.get('/admin/workbench/guide/allCategory',{   // 模块
      }).then(function (res) {
          if (res.code == 0) {
              that.$message.warning(res.data.msg);
        } else{
          that.categoryList = res.data.data.categoryList
          that.activeIndex = that.categoryList[0].id
          var att = res.data.data.categoryList
          var acc = att.slice(0)
          that.Category = acc
        }
      })
    },
    //创建确定
    BtnAdd(){
        var that = this
        if (that.addPanel) {
            axios({
              method: 'post',
              url: '/admin/workbench/guide/addBook',
              data: {
                row: {
                    category_id: that.activeIndex,
                    name: that.Row.name,
                    author: that.Row.author,
                    tag_ids: that.Row.tag_ids.join(','),
                    weigh: that.Row.weigh,
                    intro: that.Row.intro,
                    image: that.Row.image
                }
              },
            }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.addPanel = false
                that.formDialogVisible = false
                that.loadTable();
              }
            })
        }
    },
    //删除
    HandleDel(data) {
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/workbench/guide/delBook',
            data: {
              id: data
            }
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
            }
        })
      })
    },
    //编辑保存
    BtnEdit() {
      var that = this
      axios({
        method: 'put',
        url: '/admin/workbench/guide/editBook',
        data: {
          id: that.Bkdi_id,
          row: {
            category_id: that.activeIndex,
            name: that.Row.name,
            author: that.Row.author,
            tag_ids: that.Row.tag_ids.join(','),
            weigh: that.Row.weigh,
            intro: that.Row.intro,
            image: that.Row.image
          }
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.formDialogVisible = false
            that.loadTable();
          }
      })
    },
    //编辑详情
    HandleEdit(data) {
      console.log(data)
      var that = this
      that.addPanel = false
      that.editPanel = true
      that.formDialogVisible = true
      that.Bkdi_id = data.id
      that.titleMath = '修改指南合集'
      that.contentList()

      that.Row.name = data.name,
      that.Row.author = data.author,
      that.Row.intro = data.intro,
      that.Row.image = data.full_image,
      that.Row.weigh = data.weigh,
      that.Row.category_id = data.category_id
      var arr =  data.tag_obj
      var Ner_arr = []
      for (let i = 0; i < arr.length; i++) {
        Ner_arr[i]  =  parseInt(arr[i]);
      }
      that.Row.tag_ids = Ner_arr
      console.log(that.Row.tag_ids)
      //图片
      let fileArr = [];
      let fileObj = {};
      fileObj.name = "file";
      fileObj.url = data.full_image;
      fileArr.push(fileObj);
      that.fileList = fileArr
    },
    //弹出岑取消
    Close(e){
      this.dialogVisible = false
      this.addPanel = false
      this.editPanel = false
      this.formDialogVisible = false
      this.mokuai()
    },
    // 搜索
    BtnSearch() {
      var that = this
      that.page.currentPage = 1;
      that.loadTable()
    },
    //指南管理
    guideClick(e){
      if(e==1){
        this.dialogVisible = true
        this.titleMath = '指南管理'
        this.is_show = 0
      }else if( e == 2 ){
        this.dialogVisible = true
        this.titleMath = '内容管理'
        this.is_show = 1
        this.contentList()
      }
    },
    //删除指南管理
    guideDelete(row) {
      var that = this
      var ATT = that.Category
      let guideId = row.id
      if (row.type != 'default'){
        let index = ATT.findIndex(obj => obj.index  === guideId)
        ATT.splice(index, 1)
      }
      if(that.activeIndex == guideId){
        that.isguideId = true
      }
      that.Category = ATT
    },
    //删除内容管理
    guideDelete2(row) {
      var that = this
      var ATT = that.content
      let guideId = row.id
      if (row.type != 'default'){
        let index = ATT.findIndex(obj => obj.value  === guideId)
        ATT.splice(index, 1)
      }
      that.content = ATT
    },
    //创建类别
    categoryAdd(e){
      var that = this
      var ATT = that.Category
      var ARR = that.content
      if(e == 1){
        var srt = {
          name: " 新加 ",
          status: 'normal',
          type: "custom",
          id:0,
        }
        ATT.push(srt)
        that.Category = ATT
      }else if( e == 2){
        var str = {
          content: " 新加 ",
          status: 'normal',
          type: "custom",
          value: 0,
        }
        ARR.push(str)
        that.Category = ARR
      }
    },
    //指南创建确定
    guideAdd(e){
      var that = this
      var ATT = that.Category
      var ARR = that.content
      if(e == 1 ){
        axios({
          method: 'post',
          url: '/admin/workbench/guide/editCategoryMore',
          data: {
            content: ATT
          },
        }).then(function (res) {
          that.dialogVisible = false
          that.mokuai()
          if(that.isguideId == true){
            that.activeIndex = that.categoryList[0].id
            that.loadTable()
          }
        })
      }else if( e == 2 ){
        axios({
          method: 'post',
          url: '/admin/company/content/edit',
          data: {
            field_key: "guide_tag",
            content: ARR
          },
        }).then(function (res) {
          that.dialogVisible = false
          that.contentList()
        })
      }
    },
    //组件传值
    tiaozhuan(data){
      this.DetailsId = data.id
      this.isShow = true
      console.log(this.DetailsId);
    },
    // 接收值
    parentFn(){
      this.isShow = false
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.image = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-image"].clearFiles()
      this.$refs["upload-image"].handleStart(files[0])
      this.$refs["upload-image"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
  },
};
</script>

<style scoped>
.BusinessNav,
.GuidesNav{
  padding: 24px;
  margin: 15px 24px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
}
.TableIpts{
  display: flex;
  width: 50%;
  margin: 0 auto;
}
.TableIpts input{
  border-radius: 4px 0 0 4px;
  width: 60%;
  text-indent: 1.5em;
  height: 33px !important;
}
.NavLiReset{
  margin-left: 24px;
}
.NavLiReset i{
  margin-right:2px
}
.BusinessNavLi {
  height: 30px;
  line-height: 30px;
}
.MMMM{
  margin: 12px auto 0;
  width: 50%;
}
.MMMM span{
  display: inline-block;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  margin-right: 9px;
  cursor: pointer;
}

.GuidesNav{
  display: flex;
  flex-wrap: wrap;
}
.GuidesNavLi{
  width: 33.3%;
  display: flex;
  padding-bottom: 35px;
}
.GuidesNavLeft{
  width: 127px;
  height: 170px;
  margin-right: 3%;
  border-radius: 4px;
  overflow: hidden;
}
.GuidesNavAuthor{
  width: 234p;
}
.el-image{
  width: 100%;
  height: 100%;
}
.GuidesNavRight{
  flex:1;
  position: relative;
  margin-right: 1.3%;
}
.GuidesNavRight /deep/ .el-dropdown{
  position: absolute;
  top: 5px;
  right: 10px;
}
.el-icon-more{
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  transform: rotate(90deg);
}
.menu{
  width: 70px !important;
  margin-top: 0;
}
.menu__item{
  margin-bottom: 0;
}
.GuidesNavName{
  font-size: 15px;
  font-weight: 600;
  font-family: "PingFang SC";
  cursor: pointer;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.GuidesNavAuthor{
  font-size: 13px;
  color: #8C8C8C;
  padding: 7px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.GuidesNavAuthor span{
  display: inline-block;
  padding: 0 5px;
}
.GuidesNavBrief{
  height: 40px;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:2;
  overflow: hidden;
  text-overflow:ellipsis;
  cursor: pointer;
}
.GuidesNavMaterial span{
  display: inline-block;
  width: 58px;
  height: 24px;
  text-align: center;
  font-size: 12px;
  color: #1890ff;
  border: 1px solid #1890ff;
  margin-top: 16px;
  line-height: 24px;
  border-radius: 3px;
  margin-right: 15px;
}
.El_trrt {
  position: absolute;
  left: 0%;
  width: 180px;
  top: 75%;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #BFBFBF;
}
.block{
  width: 100%;
}
.hhh{
  width: 234px;
}
</style>
